<template>

    <div class="auth-component-form-input gr-19 push-1 gr-20@small">
        <div>
            <input id="email" name="email" type="text" placeholder="Mailadresse"
                   :value="userData && userData.email? userData.email : ''" @change="saveData">
            <div v-if="!validity.email" class="auth-error">Gib bitte eine gültige Mailadresse an.</div>
        </div>
    </div>

    <div>
        <div class="auth-component-action gr-19 push-1 gr-20@small">
            <base-button type="submit" class="auth-button button">zurücksetzen</base-button>
        </div>
    </div>

</template>

<script>
    export default {
        props: ['user', 'validity'],
        emits: ['saveUser'],
        data() {
            return {
                userData: {
                    email: '',
                }
            }
        },
        watch: {
            user() {
                this.userData.email = this.user.email || '';
                this.$emit('saveUser', this.userData);
            }
        },
        mounted() {
            this.userData.email = this.user.email || '';
            this.$emit('saveUser', this.userData);
        },
        methods: {
            saveData(event) {
                this.userData[event.target.id] = event.target.value;
                this.$emit('saveUser', this.userData);
            },
        }
    }
</script>


<style scoped>
</style>
